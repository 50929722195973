import Box from "@mui/material/Box";
import Blockquote from "components/Blockquote";
import DonateButton from "components/DonateButton";
import { Markdown } from "components/Markdown";

import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import img1 from "src/images/community/moving-in-and-up.jpg";

export default function Page() {
  return (
    <BlogLayout slug="moving-in-and-up">
      <Blockquote mb={0}>
        EOCP's Matilda Cleveland Transitional Housing and Families in Transition
        (FIT) offers temporary accommodations to families in Alameda County who
        are experiencing homelessness. It is our intent to provide a safe and
        secure environment for everyone residing at our facility while they work
        towards well-being and a permanent housing placement. KE's success story
        confirms why we do this work.
      </Blockquote>
      <Box component="img" src={img1} display="block" sx={{ width: "100%" }} />

      <Paragraph component="div">
        <Markdown>
          {`
*My name is KE. I am a 34-year-old single mother with a young daughter. I was born and raised in Oakland but I left California for five years and returned in 2021 to face my fears. Upon my return, we were homeless and bounced from couch to couch for two whole years. **One late night in April of this year, I called 211 to get help and I was connected to EOCP's Matilda Cleveland.***

*Prior to joining EOCP, I was already working part-time as a supervisor at UPS. **I was living paycheck to paycheck and my case manager advised me to think about a career change to secure permanent housing.** With lots of encouragement from staff, I attended several job fairs. At one of these events, I heard about a construction pre-apprenticeship program and I was excited to explore a career in the trades and joined Rising Sun Center's Opportunity Build in Berkeley. I attended 12 weeks of classroom instruction and hands-on training with retired union tradespeople and I simply loved it!*

***Last September, I was proud to graduate from the construction program**where I was able to acquire certification in OSHA-10 safety, first aid/CPR and multi-craft core skills. With assistance from Rising Sun Center I plan to get a job and gain experience as a plumber.*

*I must say the greatest support I received came from staff at EOCP. They listened and were able to provide advice and show unconditional love in their own way. My daughter and I have secured permanent housing in the community. **Thank you for giving us a chance to move in, up and out!***

**Join us by donating to EOCP** and supporting individuals and families to build strong self-care practices, secure permanent housing and move into their next stage of life.
          `}
        </Markdown>
      </Paragraph>

      <DonateButton />
    </BlogLayout>
  );
}
