import Button from "@mui/material/Button";
import * as React from "react";
import { DONATE_URL } from "src/constants";

export default function DonateButton() {
  return (
    <Button href={DONATE_URL} variant="contained" size="large">
      Donate now
    </Button>
  );
}
